import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Loading data",
	subTitle: (
		<p>
			The first part to your data warehouse is loading the data. We load
			all your data into a cloud database to give lightning fast access,
			with no sampling, at any scale.
		</p>
	)
};

const alternatingContent = [
	{
		subTitle:
			"How do I get all the data our of my analytics tools in one place?",
		content: (
			<React.Fragment>
				<p>
					We&apos;ll work with you at the start to understand your
					data requirements.
				</p>
				<p>
					How many sites, how many data sources, unique requirements
					about your own data - all the details.
				</p>
				<p>
					Then we&apos;ll go and set-up all those data sources so
					they&apos;re backfilled and automatically loading.
				</p>
				<p>
					As your business evolves and your data needs change, we can
					always come and add new sources.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "We usually will load multiple data sources",
		content: (
			<React.Fragment>
				<p>The four data sources we get the most requests for are:</p>
				<ul>
					<li>Google Search Console</li>
					<li>Log Data</li>
					<li>Google Analytics</li>
					<li>Rank data</li>
				</ul>
				<p>
					But we can integrate any kind of SEO data - everything from
					links & crawl data to internal sales data. Let us know what
					you&apos;re trying to accomplish and we can advise and talk
					through it.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Your data, your control",
		content: (
			<React.Fragment>
				<p>
					We understand that data ownership is really important for
					businesses and so for most customers we help them set-up
					their own BigQuery account and then load data into it so
					they have direct control.
				</p>
				<p>
					But if you&apos;re struggling with sign-off etc. we can also
					load data into our own BigQuery project.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	// Set images
	alternatingContent[0].image = data.alternatingImg0.childImageSharp.fluid;
	alternatingContent[1].image = data.alternatingImg1.childImageSharp.fluid;
	alternatingContent[2].image = data.alternatingImg2.childImageSharp.fluid;

	const title = "Loading data into the warehouse";
	const description =
		"Part 1: We load all your data into a cloud database to give lightning fast access, with no sampling, at any scale.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>
			<section>
				{/* <StripTitle title="How do we load data?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
				/>
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query LoadingData {
		# This gets the main image
		primaryImage: file(
			relativePath: {
				eq: "images/pages/homepage/homepage_stripprimary.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 935, maxHeight: 575) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# Get us the main alternating images
		alternatingImg0: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/people_small.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 501, maxHeight: 393) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg1: file(
			relativePath: { eq: "images/pages/homepage/bigquery_tables2.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 546, maxHeight: 326) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: { eq: "images/pages/how_does_it_work/bigquery.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 672, maxHeight: 392) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
